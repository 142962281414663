<template>
  <v-container class="mt-5 text-center">
    <v-form
      class="mx-auto login_block"
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="formSubmit"
    >
      <h2>Добро пожаловать!</h2>
      <v-text-field
        v-model="phoneInput"
        :counter="10"
        :rules="phoneRules"
        label="Телефон"
        maxlength="10"
        required
        prefix="+7"
        type="tel"
        :error-messages="phoneErrors"
      ></v-text-field>

      <v-text-field
        v-model="code"
        label="Код подтверждения"
        required
        autofocus
        maxlength="6"
        :counter="6"
        type="number"
        inputmode="numeric"
        autocomplete="one-time-code"
        v-if="isCodeSend"
        :error-messages="codeErrors"
      ></v-text-field>

<!--      <cfturnstile :sitekey="turnstile_site_key" @verify="captchaVerify" />-->
<!--      <span class="red&#45;&#45;text" v-if="captchaError"-->
<!--        >Проблемы с проверкой CAPTCHA. Обновите страницу</span-->
<!--      >-->
      <v-btn
        :disabled="!valid"
        color="primary"
        class="mt-4"
        v-if="!isCodeSend"
        type="submit"
      >
        Отправить код
      </v-btn>
      <v-btn to="/sign-up" v-if="unregistered_user" color="info" class="mr-4">
        Регистрация</v-btn
      >

      <v-btn type="submit" v-if="isCodeSend" color="primary" class="mr-4">
        Войти
      </v-btn>
    </v-form>
    <div class="mt-5">
      Вы новый агент? Для начала
      <router-link to="/sign-up">пройдите регистрацию.</router-link>
    </div>
  </v-container>
</template>

<script>
// import Turnstile from "cfturnstile-vue2";
export default {
  // components: {
  //   cfturnstile: Turnstile
  // },
  name: "Login",
  data: () => ({
    captchaToken: "",

    valid: true,
    phone: "",
    phoneRules: [
      v => !!v || "Введите телефон",
      v => /^\d+$/.test(v) || "Телефон должен состоять только из цифр",
      v => (v && v.length === 10) || "Телефон должен содержать 10 знаков"
    ],
    code: "",
    phoneErrors: [],
    codeErrors: [],
    captchaError: false,
    isCodeSend: false,
    unregistered_user: false
  }),
  mounted() {
    // если уже залогинен, перекидываем на заявку
    if (this.$store.getters.isLoggedIn) {
      this.gotoCreateLeasingForm();
    }
  },
  computed: {
    phoneInput: {
      set: function(val) {
        this.phone = val.replace(/\D+/g, "");
      },
      get: function() {
        if (this.phone === null) {
          return "";
        }
        return this.phone;
      }
    }
  },
  methods: {
    // captchaVerify(token) {
    //   console.log('captchaVerify',token);
    //   this.captchaToken = token;
    // },
    formSubmit() {
      this.isCodeSend ? this.login() : this.sendCode();
    },
    sendCode() {
      this.phoneErrors = [];
      this.captchaError = false;
      if (this.$refs.form.validate()) {
        let phone = this.phone;
        let captcha = this.captchaToken;
        this.$store
          .dispatch("sendConfirmCode", { phone, captcha })
          .then(data => {
            console.log(data);
            this.isCodeSend = true;
          })
          .catch(err => {
            err.data.forEach(item => {
              if (item.field === "phone") {
                this.phoneErrors.push(item.message);
                if (item.message.includes("Код уже отправлен")) {
                  this.isCodeSend = true;
                }
              }
              if (item.field === "captcha") {
                this.captchaError = true;
              }
            });
          });
      }
    },
    gotoCreateLeasingForm() {
      // alert(this.$store.state.user.is_pro)
      let goto_route = this.$store.state.user.is_pro
        ? "CreateLeasingRequestPro"
        : "CreateLeasingRequest";
      this.$router.push({ name: goto_route });
    },
    login() {
      this.phoneErrors = [];
      this.codeErrors = [];
      this.captchaError = false;
      let phone = this.phone;
      let code = this.code;
      let captcha = this.captchaToken;
      this.$store
        .dispatch("login", { phone, code, captcha })
        .then(() => this.gotoCreateLeasingForm()) // после логина перекидываем на заявку
        .catch(err => {
          console.log(err);
          err.data.forEach(item => {
            if (item.field === "phone") {
              this.phoneErrors.push(item.message);
            }

            if (item.field === "unregistered_user")
              this.unregistered_user = true;

            if (item.field === "code") {
              this.codeErrors.push(item.message);
            }
            if (item.field === "expire") {
              this.isCodeSend = true;
            }
            if (item.field === "captcha") {
              this.captchaError = true;
            }
          });
        });
    }
  }
};
</script>

<style scoped>
.login_block {
  max-width: 500px;
  text-align: center;
}
</style>
