import Vue from "vue";
import Axios from "axios";
import VueMask from "v-mask";
import VueMeta from "vue-meta";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";

import "@fortawesome/fontawesome-free/css/all.css";

import "@/assets/scss/site.scss";

Vue.use(VueMeta);
Vue.use(VueMask);

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
// Vue.prototype.turnstile_site_key = "0x4AAAAAAAQIRbNTVIz3ocsg"; // TODO вынести в .env

const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}
const NavbarStore = {
  showNavbar: false
};
Vue.mixin({
  data() {
    return {
      NavbarStore
    };
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
